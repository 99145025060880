import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  getAdjustmentInventoryOutbound,
  getRecallInventory,
} from '../../../../redux/inventoryActions';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import EmptyTable from '../../../../shared/utils/emptyTable/EmptyTable';
import { useTranslation } from 'react-i18next';
import StatusLabel from '../../../../shared/utils/statusLabel/StatusLabel';
import ProductIcon from '../../../../shared/utils/productIcon/ProductIcon';
import DataExport from '../../../../shared/dataExport/DataExport';
import ExportRecallList from '../exportRecall/ExportRecallList';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';

export default function OutboundAdjustment({
  openPrint,
  handleClosePrintPopup,
}) {
  const [data, setData] = useState();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const { t } = useTranslation();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getRecallInventory(page, limit);
      setData(res.data);
    };
    fetchData();
  }, [page, limit]);

  const tableRef = useRef();
  const xport = useCallback(() => {
    try {
      const wb = XLSX.utils.table_to_book(tableRef.current);
      XLSX.writeFile(wb, `recallhistory_${dayjs().format('DD-MM-YYYY')}.xlsx`);
    } catch (error) {
      console.error('Export failed:', error);
    }
  }, []);

  return (
    <TableContainer>
      <Table sx={{ minWidth: 485 }} aria-label='simple table'>
        <TableHead className='mi_table__head'>
          <TableRow>
            <TableCell>
              <FieldHead title={t('category')} />
            </TableCell>
            <TableCell>
              <FieldHead title={t('product_name')} />
            </TableCell>
            <TableCell>
              <FieldHead title={t('batch_no')} />
            </TableCell>
            <TableCell>
              <FieldHead title={t('manufacturer')} />
            </TableCell>

            <TableCell>
              <FieldHead title={t('date')} />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody className='mi_table__body'>
          {data?.data?.length > 0 ? (
            data?.data?.map((row) => <RecallAdjustBodyRow row={row} />)
          ) : (
            <TableRow className='mi_table__body_No_padding'>
              <TableCell style={{ padding: 0 }} colSpan={5}>
                <EmptyTable />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={data?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DataExport
        open={openPrint}
        onClose={handleClosePrintPopup}
        tableComponent={
          <ExportRecallList tableRef={tableRef} printData={data?.data} />
        }
        title='Recall List'
        printData={data?.data}
        xport={xport}
      />
    </TableContainer>
  );
}

function RecallAdjustBodyRow({ t, row }) {
  return (
    <TableRow
      key={row?.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        <FieldBody text={row?.productDetails?.type} />
      </TableCell>
      <TableCell>
        <ProductIcon
          type={row?.productDetails?.type}
          name={row?.productDetails?.name}
          size='tiny'
          fallback={<FieldBody text={row?.productDetails?.name} />}
        />
      </TableCell>

      <TableCell>
        <FieldBody text={row?.batchNo} color='bold' />
      </TableCell>
      <TableCell>
        <FieldBody text={row?.manufacturerDetails?.name} color='bold' />
      </TableCell>
      <TableCell>
        <FieldBody text={new Date(row?.createdAt).toLocaleDateString()} />
      </TableCell>
    </TableRow>
  );
}
