import React, { useEffect } from 'react';
import BreadCrumb from '../../../shared/utils/breadCrumb/BreadCrumb';
import { getPar, getVar, updatePAR } from '../../../redux/inventoryActions';

import { useParams, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import ViewPARcard from './ViewPARcard';
import { useTranslation } from 'react-i18next';
import ProductIcon from '../../../shared/utils/productIcon/ProductIcon';
import StatusLabel from '../../../shared/utils/statusLabel/StatusLabel';
import PermissionRoute from '../../../web/routes/routeLayers/PermissionRoute';
import Printer from '../../../shared/printer/Printer';

export default function ViewPAR() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [VaccineList, setVaccineList] = React.useState();
  const [DiluentList, setDiluentList] = React.useState();
  const [AlertList, setAlertList] = React.useState();
  const [data, setData] = React.useState();
  const [display, setDisplay] = React.useState();
  const [parApproved, setParApproved] = React.useState();

  useEffect(() => {
    const fetchData = async () => {
      const PAR = await getPar(id);
      setData(PAR?.data);
    };
    fetchData();
  }, [id, parApproved]);

  const EditPARData = {
    ...data,
    product: data?.shipmentDetails?.products?.[0]?.productId,
  };
  const handleEdit = () => {
    navigate('/user/arrival-report/product/new?form=part1', {
      state: { value: EditPARData },
    });
  };

  useEffect(() => {
    setDisplay([
      {
        title: t('general_information'),
        data: [
          {
            title: t('product_name') + ' *',
            answer: (
              <ProductIcon
                type={
                  data?.shipmentDetails?.products?.[0]?.productDetails?.type
                }
                name={
                  data?.shipmentDetails?.products?.[0]?.productDetails?.name
                }
                size='tiny'
                fallback={
                  <span>
                    {data?.shipmentDetails?.products?.[0]?.productDetails?.name}
                  </span>
                }
              />
            ),
          },
          {
            title: t('product_category') + ' *',
            answer: data?.shipmentDetails?.products?.[0]?.productDetails?.type,
          },
          {
            title: t('status'),
            answer: data?.status ? (
              <StatusLabel
                status={data?.status}
                tone={
                  data?.status === 'ACCEPTED'
                    ? 'green'
                    : data?.status === 'REJECTED'
                    ? 'red'
                    : 'blue'
                }
              />
            ) : (
              '---'
            ),
          },
          {
            title: t('country') + ' *',
            answer: data?.country,
          },
          {
            title: t('document_number') + ' *',
            answer: data?.reportNo,
          },
          {
            title: t('report_date'),
            answer: new Date(data?.reportDate).toLocaleDateString(),
          },

          // {
          //   title: "Date of Shipments",
          //   answer: new Date(data?.date_of_shipment).toLocaleDateString(),
          // },

          // {
          //   title: "Time of Shipments",
          //   answer: data?.time_of_shipment,
          // },
        ],
      },

      {
        title: t('PAR_part1_title'),
        data: [
          {
            title: t('purchase_order_number'),
            answer: data?.shipmentDetails?.poNo,
          },
          // {
          //   title: "Vaccine Description(Type and Doses/Vial)",
          //   answer: data?.shipmentDetails?.vaccineType,
          // },
          {
            title: t('country'),
            answer: data?.shipmentDetails?.originCountry,
          },
          {
            title: t('consignee'),
            answer: data?.shipmentDetails?.consignee,
          },
          {
            title: t('PAR_part1_question1'),
            answer: data?.shipmentDetails?.shortShipment.toString(),
          },
          {
            title: t('PAR_part1_question2'),
            answer: data?.shipmentDetails?.shortShipmentNotification.toString(),
          },
          {
            title: t('comments'),
            answer: data?.shipmentDetails?.comments,
          },
        ],
      },

      {
        title: t('PAR_part2_title'),
        data: [
          {
            title: t('document_number'),
            answer: data?.documents.reportNum,
          },
          {
            title: t('invoice'),
            answer: data?.documents?.invoice?.toString(),
          },
          {
            title: t('packing_list'),
            answer: data?.documents?.packingList?.toString(),
          },
          {
            title: t('product_arrival_report'),
            answer: data?.documents?.productArrivalReport?.toString(),
          },
          {
            title: t('release_certificate'),
            answer: data?.documents?.releaseCertificate?.toString(),
          },
          {
            title: t('if_other_specify'),
            answer: data?.documents?.comment,
          },
          {
            title: t('PAR_part2_question1'),
            answer: data?.documents?.type,
          },
          {
            title: t('PAR_part2_question2'),
            answer: data?.documents?.labelsAttached?.toString(),
          },
          {
            title: t('comments'),
            answer: data?.documents?.comment,
          },
        ],
      },

      {
        title: t('PAR_part3_title'),
        data: [
          {
            title: t('authorized_inspection_supervisor'),
            answer: data?.signatures?.inspectionSupervisor?.sign,
          },
          {
            title: t('authorized_inspection_date'),
            answer: new Date(
              data?.signatures?.inspectionSupervisor?.date
            ).toLocaleDateString(),
          },
          {
            title: t('central_store_inspection_manager'),
            answer: data?.signatures?.epiManager?.sign,
          },
          {
            title: t('date'),
            answer: new Date(
              data?.signatures?.epiManager?.date
            ).toLocaleDateString(),
          },
          {
            subtitle: t('date_received_by_the_office_msg'),
            title: t('date_received_by_the_office'),
            answer: new Date(
              data?.signatures?.receivedDate
            ).toLocaleDateString(),
          },
          {
            title: t('contact_person'),
            answer: data?.signatures?.contact,
          },
        ],
      },
    ]);

    setAlertList(data?.shippingIndicators.alerts);
    setVaccineList(data?.shipmentDetails?.products);
    setDiluentList(data?.shipmentDetails?.diluentDroppers);
  }, [data]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleManagePAR = async (status) => {
    const res = await updatePAR({
      parId: data?._id,
      status: status,
    });

    if (res.success) {
      setParApproved((prev) => !prev);
      toast.success(`PAR ${status}`);
    }
  };

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <div className='back_Link' onClick={() => navigate(-1)}>
            <i className='bx bx-arrow-back icon_blue'></i>
          </div>
          <h1 className='Page__headline_title_fs'>
            {t('view_product_arrival_report')}
          </h1>
        </div>

        <BreadCrumb
          url1='/user/arrival-report/product'
          Link1={t('product_arrival_report')}
          Link2={t('view_PAR')}
        />
      </div>
      <div className='Page__action_wrapper'>
        <div className='Page__left_actions'>
          <button
            className='mi_btn mi_btn_medium mi_btn_outline'
            onClick={() => {
              handlePrint();
            }}
          >
            <i className='fa-solid fa-download'></i>
            <span>{t('generate_PDF')}</span>
          </button>
          {/* <button
            className="mi_btn mi_btn_medium mi_btn_outline"
            onClick={() => {
              handleEdit();
            }}
          >
            <i className="fa-solid fa-pencil icon_blue"></i>
            <span>{t("edit")}</span>
          </button> */}
        </div>
        <PermissionRoute allowed={'RESPOND_PAR'} type='actions'>
          {data?.status === 'PENDING' && (
            <div className='Page__right_actions'>
              <button
                className='mi_btn mi_btn_medium mi_btn_approved'
                onClick={() => handleManagePAR('ACCEPTED ')}
              >
                <i className='fa-regular fa-circle-check'></i>
                <span>{t('accept')}</span>
              </button>

              <button
                className='mi_btn mi_btn_medium mi_btn_rejected'
                onClick={() => handleManagePAR('REJECTED')}
              >
                <i className='fa-regular fa-circle-xmark'></i>
                <span>{t('reject')}</span>
              </button>
            </div>
          )}
        </PermissionRoute>
      </div>

      <section className='ReviewVAR__list' ref={componentRef}>
        <Printer title={t('product_arrival_report_details')}>
          <div className='printing_body column_flex'>
            {display?.map((row, index) => (
              <ViewPARcard
                key={index}
                row={row}
                AlertList={AlertList}
                VaccineList={VaccineList}
                DiluentList={DiluentList}
              />
            ))}
          </div>
        </Printer>
      </section>
    </section>
  );
}
